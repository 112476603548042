.slider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-70%, -50%);
  color: #fff;
  z-index: 1;
}

.learnmore {
  border: 2px solid white;
  color: white;
  transition: background-color 0.3s;
  margin-top: 4rem;
}

.learnmore:hover {
  background-color: white;
  color: black;
}

.slider-title {
  font-size: 60px;
}

.slider-desc {
  font-size: 25px;
}

@media (max-width: 600px) {
  .slider-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-60%, -60%);
    color: #fff;
    z-index: 1;
  }

  .learnmore {
    border: 2px solid white;
    color: white;
    transition: background-color 0.3s;
    margin-top: 4rem;
  }

  .slider-title {
    font-size: 40px;
  }

  .slider-desc {
    font-size: 18px;
    margin-bottom: 4rem;
  }
}
