.foot {
  color: white;
  transition: color 0.3s ease;
}

.foot:hover {
  color: grey;
}

.footer-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 3rem 3rem;
}

@media (max-width: 768px) {
  .footer-wrapper {
    flex-direction: column;
    /* align-items: center; */
  }
  .about {
    padding-left: 3rem;
    margin-bottom: 2rem;
  }
  .services {
    padding-left: 4rem;
  }
}
