.image-container {
  position: relative;
  /* filter:  brightness(50%); */
}

.image-text {
  position: absolute;
  top: 40%;

  width: 100%;
  color: white;
  font-family: helveticabold;
  text-align: center;
  font-size: 30px;

  transition: font-size 0.2s ease-in-out;
}
.image-container-ie {
  display: flex;
  margin: 4rem auto;
  align-items: center;
  justify-content: space-evenly;
}
.image-text:hover {
  transition: font-size 0.2s ease-in-out;
  font-size: 80px;
}

.image-style {
  filter: brightness(60%);
  height: 350px;
  width: 35vw;
}

.my-div {
  background-image: url("../../Images/Logo-only-superfade.png");
  background-size: 50%;
  background-position: topleft;
  background-repeat: no-repeat;
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 100px;
  justify-items: center;
  align-items: center;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon img {
  /* width: 100%;
  max-width: 100px; */
  height: auto;
}

.icon-heading {
  font-size: 1.2rem;
  font-family: Helveticamedium;
  margin-top: 10px;
  margin-bottom: 5px;
  /* color: #00c746; */
}

.icon-subheading {
  font-size: 0.9rem;
  font-family: poppinsregular;
  color: #656565;
  margin: 0;
}

.input {
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  padding: 10px;
  font-size: 17px;
}
.input:hover {
  border: 2px solid #00c746;
  border-radius: 3px;
}

.sub-btn {
  text-align: center;
  font-family: helveticamedium;
  font-size: 15px;
  padding: 13px 30px;
  color: white;
  border: none;
  border-radius: 50px;
  background-color: #00c746;
}
.sub-btn:hover {
  background-color: black;
}

.elastec {
  border: 1px solid white;
  color: white;
  margin-left: 2rem;
  transition: background-color 0.3s;
}

.elastec:hover {
  background-color: white;
  color: black;
}

.elastecweb {
  border: 2px solid black;
  color: black;
  transition: background-color 0.3s;
}

.elastecweb:hover {
  border: 2px solid #009ec2;
  background-color: #009ec2;
  color: white;
}

.elastec-wrapper {
  padding: 10rem 0rem 0rem 0rem;
  display: flex;
  justify-content: center;
  color: white;
  position: absolute;
  width: 100vw;
  height: 80vh;
  z-index: 1;
}

.entrepreneur-wrapper {
  display: flex;
  align-items: center;
  padding: 20vh 7vw 20vh 5vw;
  justify-content: space-around;
}

.entrepreneur-image {
  width: 28%;
  aspect-ratio: 1/1;
  border-radius: 10px;
}

.entrepreneur-text {
  width: 65%;
  font-size: 30px;
}

.elastec-icon {
  width: 28rem;
  margin-top: 13rem;
  border-radius: 10px;
  margin-right: 5vw;
}

.elastec-title {
  font-family: poppinsextrabold;
  font-size: 60px;
  margin-left: 2rem;
  padding: 0rem 10vw 0rem 7vw;
}
.elastec-desc {
  font-size: 23px;
  font-family: poppinslight;
  color: #d9d9d9;
  margin-bottom: 3rem;
  margin-left: 2rem;
  padding: 0rem 10vw 0rem 7vw;
}

.cform {
  width: 46%;
}
.elastec-bg {
  height: 100vh;
}
.reach-out-heading {
  font-size: 60px;
}
@media (max-width: 600px) {
  .icon-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    /* Styles for smaller screens with two items in a row */
  }
  .elastec-wrapper {
    flex-direction: column;
    /* align-items: center; */
    padding: 0;
    margin: auto;
  }
  .entrepreneur-wrapper {
    flex-direction: column;
  }
  .elastec-icon {
    width: 80%;
    margin-top: 5rem;
    margin-left: 2rem;
  }

  .elastec-title {
    font-size: 40px;
    width: 90%;
    margin: auto;
  }
  .elastec-desc {
    font-size: 18px;
    width: 90%;
    margin: 3rem auto;
  }
  .entrepreneur-image {
    width: 90%;
  }

  .entrepreneur-text {
    width: 90%;
    font-size: 17px;
    margin-bottom: 6vh;
  }
  .cform {
    width: 78%;
  }
  .image-container-ie {
    flex-direction: column;
  }
  .image-style {
    height: 300px;
    width: 100%;
    margin: auto;
    margin-bottom: 3rem;
  }

  .elastec-bg {
    height: 80vh;
  }
  .reach-out-heading {
    font-size: 40px;
  }

  .image-text {
    position: absolute;
    top: 35%;

    width: 100%;
    color: white;
    font-family: helveticabold;
    text-align: center;
    font-size: 40px;

    transition: none;
  }

  .image-text:hover {
    transition: none;
    font-size: 40px;
  }
}
