.service-text-wrapper {
  /* width: 70%; */
  flex: 1;
  margin-top: 3rem;
  padding: 0 8vw;
}
.service-heading {
  top: 50%;
}
.service-img-r {
  aspect-ratio: 16/9;
  border-radius: 10px;
  width: 45vw;
  object-fit: cover;
  flex: 1;
  margin-right: 5vw;
}
.service-img-l {
  aspect-ratio: 16/9;
  border-radius: 10px;
  width: 45vw;
  object-fit: cover;
  flex: 1;
  margin-left: 5vw;
}
.aboutus-text {
  margin: 0 5rem;
}
.elastec-text {
  text-align: left;
}
.about-img {
  width: 40vw;
  margin-right: 5vw;
}
.line {
  height: 100px;
}

.elastec-text-wrapper {
  padding: 0 8rem;
}
.elastec-img {
  width: 27vw;
}
.tsc-img {
  width: 34vw;
}
.aboutus-heading {
  margin-left: 5rem;
}
@media (max-width: 600px) {
  .service-section-wrapper {
    flex-direction: column;
  }
  .service-heading {
    top: 25%;
  }
  .service-section-wrapper-l {
    flex-direction: column-reverse;
  }
  .service-img-r {
    width: 90vw;
    margin: 0;
  }
  .about-img {
    width: 95vw;
    margin: 0;
  }
  .service-img-l {
    width: 90vw;
    margin: 0;
  }
  .aboutus-text {
    margin: 4vh 10vw;
  }
  .elastec-text {
    text-align: left;
  }
  .elastec-text-wrapper {
    padding: 0 3rem;
  }
  .line {
    height: 50px;
  }
  .elastec-img {
    width: 34vw;
  }
  .tsc-img {
    width: 49vw;
  }
  .aboutus-heading {
    margin-left: 10vw;
  }
}
