body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* HELVETICA */

@font-face {
  font-family: "HelveticaExtBlk";
  src: url(./Fonts/HelveticaNowDisplay-ExtBlk.otf);
}

@font-face {
  font-family: "HelveticaMedium";
  src: url(./Fonts/HelveticaNowDisplay-Medium.otf);
}

@font-face {
  font-family: "HelveticaRegular";
  src: url(./Fonts/HelveticaNowDisplay-Regular.otf);
}

@font-face {
  font-family: "HelveticaThin";
  src: url(./Fonts/HelveticaNowDisplay-Thin.otf);
}

@font-face {
  font-family: "HelveticaLight";
  src: url(./Fonts/HelveticaNowDisplay-Light.otf);
}

@font-face {
  font-family: "HelveticaBold";
  src: url(./Fonts/HelveticaNowDisplay-Bold.otf);
}

/* POPPINS */

@font-face {
  font-family: "PoppinsThin";
  src: url(./Fonts/Poppins-Thin.ttf);
}

@font-face {
  font-family: "PoppinsExtraLight";
  src: url(./Fonts/Poppins-ExtraLight.ttf);
}

@font-face {
  font-family: "PoppinsLight";
  src: url(./Fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: "PoppinsRegular";
  src: url(./Fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsMedium";
  src: url(./Fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: "PoppinsSemibold";
  src: url(./Fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(./Fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "PoppinsExtrabold";
  src: url(./Fonts/Poppins-ExtraBold.ttf);
}

@font-face {
  font-family: "PoppinsBlack";
  src: url(./Fonts/Poppins-Black.ttf);
}

a {
  padding: 0;
  text-decoration: none;
  margin: 0;
}
